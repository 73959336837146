import { useQuery } from 'react-query'
import { ApiClient } from '../../clients'
import { useConfig } from '../config'

export function useGetAllAgencies() {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, data, isError, refetch } = useQuery(
    ['agencies'],
    async () => {
      return await apiClient.getAllAgencies()
    }
  )

  return {
    allAgenciesData: data,
    isAgenciesLoading: isLoading,
    isAgenciesError: isError,
    refetchAllAgenciesData: refetch,
  }
}
