import { Action } from 'src/clients'
import { useProfile } from 'src/hooks'
import { hasPermission } from 'src/utils/auth'

import {
  UserManagementHeading,
  UserManagementTable,
} from 'src/components/UserManagementTable'
import { useState } from 'react'

export function SchoolUserManagement(): JSX.Element {
  const { profileData, isGetProfileLoading } = useProfile()
  const [search, setSearch] = useState<{ search: string; filter: string }>({
    search: '',
    filter: '',
  })

  const handleFiltersChange = (searchText: string, filter: string) => {
    setSearch({ search: searchText, filter: filter })
  }

  return (
    <>
      <UserManagementHeading
        canCreateUser={
          !isGetProfileLoading &&
          profileData !== undefined &&
          hasPermission(profileData.userType, Action.CREATE)
        }
        onFilterChange={handleFiltersChange}
      />
      <UserManagementTable
        currentSessionUser={profileData}
        isGetProfileLoading={isGetProfileLoading}
        filter={search.filter}
        search={search.search}
      />
    </>
  )
}
