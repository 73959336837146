import { Flex, Tabs } from '@applyboard/crystal-ui'
import { NavBars } from '../../components/NavBars'
import { PageHeading } from 'src/components/PageHeading'
import { SchoolUserManagement } from './components/SchoolUserManagement'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AgentUserManagement } from './components/AgentUserManagement'

export function UserManagementPage(): JSX.Element {
  const TABS: Array<{ index: string; value: string }> = [
    {
      index: '1',
      value: '/profiles/school-users',
    },
    {
      index: '2',
      value: '/profiles/agent-users',
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()

  const [selectedTab, setSelectedTab] = useState(
    TABS.find((tab) => tab.value === location.pathname)?.index || '1'
  )

  const onChange = (itm: string) => {
    setSelectedTab(itm)
    navigate(
      TABS.find((tab) => tab.index === itm)?.value || '/profiles/school-users'
    )
  }

  return (
    <NavBars selectedMenuItem="All Users">
      <Flex p={{ xs: 4, sm: 12 }} direction="column" grow={1}>
        <Flex p={{ sm: 4 }} direction="column">
          <PageHeading
            title="User Management"
            imageSrc="/user-management-icon.svg"
            imageAlt="user management icon"
          />
        </Flex>
        <Tabs
          align="left"
          aria-label=""
          aria-labelledby=""
          emphasis="highlighted"
          onChange={onChange}
          orientation="horizontal"
          value={selectedTab}
        >
          <Tabs.Item label="School users" value="1">
            <SchoolUserManagement />
          </Tabs.Item>
          <Tabs.Item label="Agent users" value="2">
            <AgentUserManagement />
          </Tabs.Item>
        </Tabs>
      </Flex>
    </NavBars>
  )
}
