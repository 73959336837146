import { ProfileResource } from '@backend/platform-api/resources/profile'
import { GetProfilesResponse } from '@backend/platform-api/routes/profiles/GET/types'
import { CreateProfileRequest } from '@backend/platform-api/routes/profiles/POST/types'
import { CreateAgentRequest } from '@backend/platform-api/routes/v1/agents/POST/types'
import { GetAgenciesResponse } from '@backend/platform-api/routes/v1/agencies/GET/types'
import { GetProfileResponse } from '@backend/platform-api/routes/profiles/{id}/GET/types'
import { UpdateProfileRequest } from '@backend/platform-api/routes/profiles/{id}/PATCH/types'

import { GetFrontendConfigResponse } from '@backend/platform-api/routes/frontend-config/GET/types'
import { UserSession } from '../auth'

import {
  Config,
  CreateProfileProps,
  EditProfileProps,
  ProfileData,
  CreateAgentProps
} from './types'

export class ApiClient {
  private static API_ENDPOINT = `https://${window.location.host}/api`;
  private static PROFILE_ENDPOINT = `${ApiClient.API_ENDPOINT}/profiles`
  private static AGENTS_ENDPOINT = `${ApiClient.API_ENDPOINT}/v1/agents`
  private static AGENCIES_ENDPOINT = `${ApiClient.API_ENDPOINT}/v1/agencies`

  static PAGE_SIZE = 100

  private readonly userSession: UserSession

  constructor(config: Config) {
    this.userSession = new UserSession(config)
  }

  async getProfileData(id: string): Promise<ProfileData> {
    const authToken = await this.userSession.getAuthToken()

    const fetchResponse = await fetch(`${ApiClient.PROFILE_ENDPOINT}/${id}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
      },
    })

    if (fetchResponse.ok) {
      const response = (await fetchResponse.json()) as GetProfileResponse
      const profileResource = response.data as ProfileResource

      return getProfileDataFromResource(profileResource)
    } else {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to get profile data.')
    }
  }

  async getAllProfiles(page: number = 1): Promise<Array<ProfileData>> {
    const authToken = await this.userSession.getAuthToken()

    const fetchResponse = await fetch(
      `${ApiClient.PROFILE_ENDPOINT}?page[size]=${ApiClient.PAGE_SIZE}&page[number]=${page}`,
      {
        method: 'GET',
        headers: {
          Authorization: authToken,
        },
      }
    )

    if (fetchResponse.ok) {
      const response = (await fetchResponse.json()) as GetProfilesResponse

      const profileData = response.data.map((profileResource) =>
        getProfileDataFromResource(profileResource)
      )

      if (
        response.meta?.count !== undefined &&
        page + 1 * ApiClient.PAGE_SIZE < response.meta.count
      ) {
        return [...profileData, ...(await this.getAllProfiles(page + 1))]
      }

      return profileData
    } else {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to get profile data.')
    }
  }

  async editProfile({ id, profileData }: EditProfileProps) {
    const authToken = await this.userSession.getAuthToken()

    const requestBody: UpdateProfileRequest = {
      data: {
        id: id,
        attributes: profileData,
        type: 'profile',
      },
    }

    const fetchResponse = await fetch(`${ApiClient.PROFILE_ENDPOINT}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(requestBody),
      headers: {
        Authorization: authToken,
      },
    })

    if (!fetchResponse.ok) {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to edit profile.')
    }
  }

  async deleteProfile(username: string): Promise<void> {
    const authToken = await this.userSession.getAuthToken()

    const fetchResponse = await fetch(
      `${ApiClient.PROFILE_ENDPOINT}/${username}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: authToken,
        },
      }
    )

    if (!fetchResponse.ok) {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to delete profile data.')
    }
  }

  async createProfile(profileData: CreateProfileProps): Promise<void> {
    const authToken = await this.userSession.getAuthToken()

    const payload = this.userSession.getAuthTokenPayload()
    const schoolId = payload.schoolId

    const requestBody: CreateProfileRequest = {
      data: {
        attributes: profileData,
        type: 'profile',
        relationships: {
          school: {
            data: {
              type: 'school',
              id: schoolId,
            },
          },
        },
      },
    }

    const fetchResponse = await fetch(ApiClient.PROFILE_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        Authorization: authToken,
      },
    })

    if (!fetchResponse.ok) {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to create profile.')
    }
  }

  async createAgent(agentData: CreateAgentProps): Promise<void> {
    const authToken = await this.userSession.getAuthToken()

    const requestBody: CreateAgentRequest = {
      data: {
        attributes: agentData,
        type: 'agent',
      },
    }

    const fetchResponse = await fetch(ApiClient.AGENTS_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        Authorization: authToken,
      },
    })

    if (!fetchResponse.ok) {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to create agent.')
    }
  }

  async getAllAgencies(): Promise<GetAgenciesResponse> {
    const authToken = await this.userSession.getAuthToken()

    const fetchResponse = await fetch(ApiClient.AGENCIES_ENDPOINT,
      {
        method: 'GET',
        headers: {
          Authorization: authToken,
        },
      }
    )

    if (fetchResponse.ok) {
      const response = (await fetchResponse.json() as GetAgenciesResponse)
      return response
    } else {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to get agency data.')
    }
  }

  static async getConfiguration(): Promise<Config> {
    const fetchResponse = await fetch(
      `${ApiClient.API_ENDPOINT}/frontend-config`,
      {
        method: 'GET',
      }
    )

    if (fetchResponse.ok) {
      const response = (await fetchResponse.json()) as GetFrontendConfigResponse
      return response.data.attributes
    } else {
      console.debug({ fetchResponse }, 'API Fetch Error')
      throw new Error('Failed to get config data.')
    }
  }
}

function getProfileDataFromResource(resource: ProfileResource): ProfileData {
  return {
    ...resource.attributes,
    id: resource.id,
    schoolId: resource.relationships.school.data.id,
  }
}
