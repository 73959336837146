import { Flex, useToasts } from '@applyboard/crystal-ui'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { formatErrorMessage } from 'src/utils'
import { EditProfileProps, UserType } from '../../clients'
import { NavBars } from '../../components/NavBars'
import { PageHeading } from '../../components/PageHeading'
import { ProfileForm } from '../../components/ProfileForm'
import { useEditProfile, useGetProfile, useProfile } from '../../hooks'
import { LoadingPage } from '../LoadingPage'

export function EditProfilePage(): JSX.Element {
  const { id } = useParams()

  const { profileData, isGetProfileLoading: isGetProfileToBeEditedLoading } =
    useGetProfile({ id })
  const { editProfile, isEditingProfile } = useEditProfile()
  const navigate = useNavigate()
  const toasts = useToasts()
  const {
    profileData: currentSessionUser,
    isGetProfileLoading: isGetCurrentSessionUserLoading,
  } = useProfile()
  const currentSessionUserIsResolved =
    !isGetCurrentSessionUserLoading && currentSessionUser

  if (isGetProfileToBeEditedLoading || !currentSessionUserIsResolved) {
    return <LoadingPage />
  }

  if (!profileData) {
    // TODO: add a toast to say the profile data could not be fetched?
    return <Navigate to={'/profiles/add'} />
  }

  const handleSubmit = (profileToEdit: EditProfileProps['profileData']) => {
    if (id) {
      if (currentSessionUser.userType === UserType.staff) {
        profileToEdit = {
          givenName: profileToEdit.givenName,
          familyName: profileToEdit.familyName,
        }
      }

      editProfile(
        { id, profileData: profileToEdit },
        {
          onSuccess: () => {
            navigate('/profiles/school-users')
            toasts.positive('Success: Updated user.')
          },
          onError: (error: Error) => {
            const formattedError = formatErrorMessage(error)
            toasts.negative(formattedError)
          },
        }
      )
    }
  }

  return (
    <NavBars selectedMenuItem="All Users">
      <Flex p={{ xs: 4, sm: 12 }} direction="column">
        <PageHeading
          title="Edit User"
          imageSrc="/user-management-icon.svg"
          imageAlt="edit user icon"
        />
        <ProfileForm
          currentSessionUser={currentSessionUser}
          profileDataToBeEdited={profileData}
          isLoading={isEditingProfile}
          onSubmit={handleSubmit}
        />
      </Flex>
    </NavBars>
  )
}
