import { Flex, useToasts } from '@applyboard/crystal-ui'
import { Navigate, useNavigate } from 'react-router-dom'
import { formatErrorMessage } from 'src/utils'
import { CreateAgentProps, UserType } from '../../clients'
import { NavBars } from '../../components/NavBars'
import { PageHeading } from '../../components/PageHeading'
import { useProfile } from '../../hooks'
import { LoadingPage } from '../LoadingPage'
import { AgentForm } from 'src/components/AgentForm'
import { useCreateAgent } from 'src/hooks/agents/useCreateAgent'

export function AddAgentPage(): JSX.Element {
  const { createAgent, isCreatingAgent } = useCreateAgent()
  const navigate = useNavigate()
  const toasts = useToasts()
  const {
    profileData: currentSessionUser,
    isGetProfileLoading: isGetCurrentSessionUserLoading,
  } = useProfile()
  const currentSessionUserIsResolved =
    !isGetCurrentSessionUserLoading && currentSessionUser

  if (!currentSessionUserIsResolved) {
    return <LoadingPage />
  }

  if (currentSessionUser.userType !== UserType.admin && currentSessionUser.userType !== UserType.owner) {
    toasts.warning(`You don't have permission to see this page`)
    return <Navigate to={'/profiles/agent-users'} />
  }

  const handleSubmit = (agentData: CreateAgentProps) => {
    createAgent(agentData, {
      onSuccess: () => {
        navigate('/profiles/agent-users')
        toasts.positive('Added a user successfully.')
      },
      onError: (error) => {
        const formattedError = formatErrorMessage(error)
        toasts.negative(formattedError)
      },
    })
  }

  return (
    <NavBars selectedMenuItem="All Users">
      <Flex p={{ xs: 4, sm: 12 }} direction="column">
        <PageHeading
          title="Add Agent"
          imageSrc="/user-management-icon.svg"
          imageAlt="add agent icon"
        />
        <AgentForm
          currentSessionUser={currentSessionUser}
          onSubmit={handleSubmit}
          isLoading={isCreatingAgent}
        />
      </Flex>
    </NavBars>
  )
}
