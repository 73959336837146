import { useMutation } from 'react-query'
import { ApiClient, CreateAgentProps } from '../../clients'
import { useConfig } from '../config'

export function useCreateAgent() {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, mutate, error, isError } = useMutation<
    void,
    Error,
    CreateAgentProps
  >(async (props: CreateAgentProps) => {
    return await apiClient.createAgent(props)
  })

  return {
    isCreatingAgent: isLoading,
    isCreateAgentError: isError,
    createAgentError: error,
    createAgent: mutate,
  }
}
